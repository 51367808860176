
<template>      
    <div class="bac">      
        <div class="section">
	        <div class="container">
				<div class="row service-wrapper-row">
					<a href="/BusinessWordView" style="color: none;">
        			<div class="col-sm-4">
        				<div class="service-image">
        					<img src="images/swipers/word.png" alt="Color Schemes">
        				</div>
        			</div>
        			<div class="col-sm-8">
    					<h3>Word制作与美化</h3>
    					<p>
    						简历、总结、合同、公文等多种类
    					</p>
    					<p>
    						Word production and beautification.
							If you are not satisfied with the design, you can modify it for free until you are satisfied!
    						
    					</p>
    				</div>
    				</a>
				</div>
				
				<div class="row service-wrapper-row">
					<a href="/BusinessPptView" style="color: none;">
        			<div class="col-sm-4">
        				<div class="service-image">
        					<img src="images/swipers/ppt.png" alt="Color Schemes">
        				</div>
        			</div>
        			<div class="col-sm-8">
    					<h3>PPT制作与美化</h3>
    					<p>
    						工作汇报、计划书、企业宣传、产品发布、个人简介、节日庆典、毕业答辩、政府党建
    					</p>
    					<p>
    						PPT production and beautification.
							If you are not satisfied with the design, you can modify it for free until you are satisfied!
    					</p>
    				</div>
    				</a>
				</div>
				
				<div class="row service-wrapper-row">
					<a href="/BusinessExcelView" style="color: none;">
        			<div class="col-sm-4">
        				<div class="service-image">
        					<img src="images/swipers/excel.png" alt="Responsive">
        				</div>
        			</div>
        			<div class="col-sm-8">
    					<h3>Excel表格制作与美化</h3>
    					<p>
    						仓储、财务、营销、行政等
    					</p>
    					<p>
    						Excel production and beautification.
    						If you are not satisfied with the design, you can modify it for free until you are satisfied!
    					</p>
    				</div>
    				</a>
				</div>
				
				<div class="row service-wrapper-row">
					<a href="/BusinessPsView" style="color: none;">
        			<div class="col-sm-4">
        				<div class="service-image">
        					<img src="images/swipers/design.png" alt="Flags">
        				</div>
        			</div>
        			<div class="col-sm-8">
    					<h3>平面广告设计与电商美工</h3>
    					<p>
    						海报、展板、卡片、证书、邀请函、优惠券、宣传册设计，电商首页、主图、详情页设计
    					</p>
    				</div>
    				</a>
	        	</div>
	        	<div class="row service-wrapper-row">
	        		<a href="/#" style="color: none;">
        			<div class="col-sm-4">
        				<div class="service-image">
        					<img src="images/swipers/code.png" alt="Responsive">
        				</div>
        			</div>
        			<div class="col-sm-8">
    					<h3>简单网站、程序搭建</h3>
    					<p>
    						个人网站开发、企业官网开发、HTML网页小游戏开发、Android程序开发、微信小程序开发
    					</p>
    					<p>
    						A certain system of service, take your every need seriously!
    					</p>
    				</div>
    				</a>
				</div>
	        </div>
	    </div>
    </div>
</template>
<script>


export default {
    
}
</script>
<style>
    .bac{
        background-image: url("../../../public/static/image/busnuiesbg.jpg");
    }


</style>